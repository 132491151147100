<template>
  <div>
    <div class="title-sticky">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="product in products"
            :key="product.title"
            class="py-0"
            :md="12 / products.length"
            cols="12">
            <h2 class="title-of-product">
              {{ product.title }}
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      v-for="(header, i) in headers"
      :key="`${header.value}__${i}`"
      class="item-wrapper">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="product in products"
            :key="product.title"
            :md="12 / products.length"
            cols="12">
            <div class="d-flex">
              <p
                class="font-weight-bold row-data__header">
                {{ header.value }}
              </p>
              <div
                v-if="isRequiredField(header.id)"
                class="required-field">
                *
              </div>
            </div>
            <ProductCell
              :view="view"
              :header="header"
              disabled-edit-mode
              :item="product.item"
              class="pa-0"
              :style="{ justifyContent: header.align === 'right' ? 'flex-end' : 'inherit' }" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import ProductCell from '@/components/Product/ProductCell';
import { mapGetters } from 'vuex';
export default {
  name: 'ProductCellCollectionsFormListEqual',
  components: {
    ProductCell,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: 'productCreate',
    },
    products: {
      type: Array,
      default: () => ({
      }),
    },
  },
  computed: {
    ...mapGetters('Collections', [
      'collectionsSchema',
    ]),
  },
  methods: {
    isRequiredField(id) {
      return this.collectionsSchema?.requiredFields?.includes(id);
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
.title-of-product {
  font-weight: 600;
  font-size: 20px;
  background: #fff;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 17px;
  }
}
.title-sticky {
  font-weight: 600;
  font-size: 20px;
  position: sticky;
  top: -10px;
  z-index: 1;
  background: #fff;
}
</style>
