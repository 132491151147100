<template>
  <app-dialog
    v-model="dialogForFields"
    :value.sync="dialogForFields"
    width="1124"
    content-class="v-dialog__form"
    persistent>
    <v-card>
      <v-card-title>
        {{ columnTitle }}
        <v-icon
          color="#fff"
          @click="dialogForFields = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-row
          v-if="defaultTableHeadersToPreview.length"
          class="ma-0"
          justify="space-between">
          <v-col
            cols="12"
            class="px-0 py-0">
            <ProductCellFieldProductSearch
              v-if="productToUpdate"
              input-class="mb-6 pt-0"
              wrapper-class="search-component__wrapper-equal"
              search-wrapper-class="search-items__wrapper-equal"
              :default-table-headers-to-preview="defaultTableHeadersToPreview"
              :products="products" />
          </v-col>
        </v-row>
        <v-card-actions
          v-if="!showSpinner"
          class="pt-6 justify-end pb-3">
          <v-btn
            outlined
            color="lightBlue"
            class="mr-2"
            @click="dialogForFields = false">
            Cancel
          </v-btn>
          <v-btn
            color="lightBlue"
            @click="addToProject">
            Add to Project
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </app-dialog>
</template>
<script>
import {
  mapState, mapGetters,
} from 'vuex';
import ProductCellFieldProductSearch
  from '@/components/Product/ProductCell/ProductCellFieldProduct/ProductCellFieldProductSearch';
export default {
  name: 'ProductCellFieldProductEqualAdding',
  components: {
    ProductCellFieldProductSearch,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    columnTitle: {
      type: String,
      default: 'Product Equal',
    },
  },
  computed: {
    ...mapState(['showSpinner']),
    ...mapState('Collections', ['productToUpdate']),
    ...mapGetters('ProjectDetailsTableSchedule', ['defaultTableHeadersToPreview']),
    ...mapGetters('Collections', ['selectedSchema']),
    products() {
      return [
        {
          title: 'Base Product', item: this.item,
        },
        {
          title: 'Equal Product', item: this.productToUpdate,
        },
      ];
    },
    dialogForFields: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('manageDialog', val);
      },
    },
  },
  methods: {
    addToProject() {
      this.$emit('selectFields');
    },
  },
};
</script>
<style scoped lang="scss">
.v-card {
  &__text {
    padding: 0 24px !important;
  }
}
  .title-of-product {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    position: sticky;
    top: 0;
    z-index: 1;
    background:#fff;
  }
  ::v-deep .item-wrapper {
    margin-bottom: 24px;
  }
  ::v-deep .v-dialog__form {
    overflow: hidden;
    @media only screen and (max-width: 959px)  {
      overflow: auto;
    }
  }
  .preview-block {
    max-height: 55vh;
    padding-top: 0;
    @media only screen and (max-width: 959px)  {
      max-height: inherit;
    }
  }
  .preview-block__old {
    max-height: 55vh;
    padding-top: 0;
    overflow: auto;
    @media only screen and (max-width: 959px)  {
      max-height: inherit;
    }
  }
</style>
